<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link to="/" class="d-flex align-center">
            <v-img
              :src="require(`@/assets/images/logos/logo-${$vuetify.theme.dark ? 'dark' : 'light'}.svg`)"
              max-height="auto"
              max-width="250px"
              alt="logo"
              contain
              class=""
            ></v-img>
          </router-link>
        </v-card-title>

        <v-card-text>
          <p class="text-2xl font-weight-light text--primary text-center mb-0">Seja bem-vindo!</p>
          <p class="text-center mb-2">Faça o login na sua conta para iniciar.</p>
        </v-card-text>

        <v-card-text>
          <v-form>
            <v-text-field
              v-model="email"
              type="email"
              outlined
              label="E-mail"
              hide-details
              class="mb-3"
              :disabled="loading"
              @keyup.enter="$refs.password.focus"
            ></v-text-field>

            <v-text-field
              ref="password"
              v-model="password"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Senha"
              placeholder="············"
              :append-icon="isPasswordVisible ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
              hide-details
              @click:append="isPasswordVisible = !isPasswordVisible"
              :disabled="loading"
              @keyup.enter="performLogin"
            ></v-text-field>

            <div class="d-flex align-center justify-space-between flex-wrap">
              <!-- <v-checkbox label="Lembrar senha" hide-details class="me-3 mt-1"> </v-checkbox> -->

              <v-btn text x-small link @click="forgotPassword" class="mt-1"> Esqueceu sua senha? </v-btn>
            </div>

            <v-btn block x-large color="primary" class="mt-6" @click="performLogin" :loading="loading"> Entrar </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      email: "",
      password: "",
      isPasswordVisible: false,
      loading: false
    };
  },
  methods: {
    ...mapActions("auth", ["login"]),
    forgotPassword() {
      this.$router.push({ name: "recover-password", query: { email: this.email } });
    },
    async performLogin() {
      try {
        this.loading = true;
        let a = await this.login({
          email: this.email,
          password: this.password
        });

        let to = this.$route.query.redirect || "/dashboard";
        this.$router.push(to);
      } catch (e) {
        this.$toast.fire({
          title: e.message,
          icon: "error"
        });
      } finally {
        this.loading = false;
      }
    }
  },
  computed: {
    ...mapGetters("auth", ["isAuthenticated"])
  },
  mounted() {
    if (this.isAuthenticated) {
      this.$router.push("/dashboard");
    }
  }
};
</script>

<style lang="scss">
@import "~@/plugins/vuetify/default-preset/preset/pages/auth.scss";
</style>
